<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      :class="[posterClass]"
      class="h-full w-full thumbnail relative big rounded-lg aspect-video bg-center bg-no-repeat"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="forceLive || useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <div class="flex flex-col">
      <slot name="title">
        <h4 class="text-title-small text-white opacity-[0.87] font-bold">
          {{ label }}
        </h4>
      </slot>
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  forceLive: Boolean,
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();
const posterClass = ref();

onMounted(() => {
  posterStyle.value = useFallbackImage().style;
});

const imageToShow = computed(() => {
  /**
   * for live program:
   * in misc carousel: if no horizontalImage in place, use channel logo
   */
  if (
    props.item.type == "LiveProgram" &&
    Object.keys(props.item).includes("channel")
  ) {
    const images = useMediaAssets(props.item.channel);
    posterStyle.value = "bg-contain";
    return images.logo;
  }

  return props.item.images?.horizontalimage;
});

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    posterClass.value = "bg-cover";
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
